

































































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
let ete: any = []

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})
export default class EmailVerify extends Vue {
  private emailUser: string | null= ''
  private verifyCode = []
  private flatValue = ''
  private isError = false
  private isResend = false
  private created (): void {
    localStorage.setItem('isAuth', 'false')
    // if (localStorage.getItem('page')) {
    //   this.$router.push('login/email')
    // } else {
    //   localStorage.setItem('page', '1')
    // }
    localStorage.setItem('testResult', '')
  }

  private resendEmail ():void{
    this.verifyCode = []
    this.sendCodeAgin()
  }

  private updateCode (): void {
    this.flatValue = this.verifyCode.join('')
  }

  private sendCodeAgin ():Promise<void> {
    this.isResend = true
    var data = {
      email: this.emailUser, login_mode: true
    }
    return this.$store.dispatch('auth/emailOtpSent', data)
  }

  private submit (): void {
    console.log('email', this.emailUser)
    this.emailVerify().then((response) => {
      if (response) {
        console.log('response', response)
        this.$store.dispatch('auth/setIsAuth', true)
        this.isError = false
        const data = this.$store.getters['auth/getUserSessionData']
        // if (data !== null && 'is_demographic_filled' in data && 'fullName' in data && 'passportNumber' in data) {
        //   const userInfo = {
        //     fullName: data.fullName,
        //     passportNumber: data.passportNumber,
        //     flyingDate: data.flyingDate
        //   }
        // this.$store.dispatch('walkthrough/setUserInfo', userInfo)
        this.$router.push({ name: 'demographic welcome' })
        // }
      } else {
        this.isError = true
        this.$store.dispatch('auth/setIsAuth', false)
      }
    })
  }

  private emailVerify ():Promise<boolean> {
    var data = {
      email: this.emailUser, code: this.verifyCode.join('')
    }
    return this.$store.dispatch('auth/verifyEmailOtpSent', data)
  }

  public triggerEvent (): void {
    const a = setInterval(() => {
      const buttons = document.getElementsByClassName('LPMcontainer LPMoverlay')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const button: HTMLButtonElement | any = buttons.length > 0 ? buttons[0] : null
      if (button) {
        button.click()
        clearInterval(a)
      }
    }, 10)
  }

  private mounted (): void {
    ete = document.querySelectorAll('.primary-button')
    this.emailUser = localStorage.getItem('newEmail')
    const form:any = document.querySelector('form')
    if (form) {
      form.addEventListener('input', EmailVerify.changeFocus)
      const els:any = document.querySelectorAll('.input_field')
      if (els) {
        els[0].addEventListener('paste', this.handlePate)
      }
    }

    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private static changeFocus (evt: any): void {
    const input = evt.target
    if (input.nextElementSibling && input.value) {
      input.nextElementSibling.focus()
    }

    if (input.nextElementSibling === null) {
      ete[0].focus()
    }
  }

  private handlePate (evt: any): void {
    this.verifyCode = evt.clipboardData.getData('text').replace(/(\r\n|\n|\r)/gm, '').trim().split('')
    if (this.verifyCode.length > 3) {
      ete[0].focus()
    }
  }

  private destroyed () {
    localStorage.removeItem('page')
  }
}
